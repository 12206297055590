import { Layout } from "../UI/Layout/Layout.tsx";
import { useClassRoomIdParamsOrThrow } from "../ClassRoom/useClassRoomIdParamsOrThrow.ts";
import { useProgressCheckUserList } from "./useProgressCheckUserList.ts";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import dayjs from "dayjs";
import { Card } from "../UI/Card.tsx";
import { ErrorFeedback } from "../UI/Feedback/ErrorFeedback.tsx";
import { Tabs } from "../UI/Tabs/Tabs.tsx";
import { TabItem } from "../UI/Tabs/TabItem.tsx";
import { useCallback, useState } from "react";
import {
  ProgressCheckForm,
  ProgressCheckFormInput,
} from "./ProgressCheckForm.tsx";
import { ProgressCheckRecommendation } from "./ProgressCheckRecommendation.tsx";
import {
  ClassRoomWithoutParticipantFragment,
  ProgressCheckUserDto,
  ProgressCheckUserFragment,
} from "../gql/graphql.ts";
import _values from "lodash.values";
import { ProgressBar } from "../UI/ProgressBar.tsx";
import { useGiveProgressCheckFeedback } from "./useGiveProgressCheckFeedback.ts";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { GraphQLError } from "graphql";
import { useToProgressCheckFeedbackThankYou } from "./useToProgressCheckFeedbackThankYou.ts";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";

export const GiveProgressCheckFeedbackScreenPath =
  "/progress-checks/:classRoomId/give-feedback";

type ProgressCheckFeedbackBagDto = {
  userId: string;
  accuracy?: number;
  comprehension?: number;
  vocabularyRange?: number;
  fluency?: number;
  recommendation?: ProgressCheckRecommendation[];
  notes?: string;
  passed?: boolean | null;
  noShow: boolean;
};

function GiveProgressCheckFeedbackScreenInner({
  progressCheck,
  participants,
}: {
  participants: readonly ProgressCheckUserFragment[];
  progressCheck: ClassRoomWithoutParticipantFragment;
}) {
  const { show, showPopup, description, title } = useMessagePopupController(
    5 * 1000,
  );

  const [selectedParticipantIndex, setSelectedParticipantIndex] =
    useState<number>(0);

  const { giveProgressCheckFeedback } = useGiveProgressCheckFeedback();

  const { goToProgressCheckFeedbackThankYou } =
    useToProgressCheckFeedbackThankYou();
  const [progressCheckFeedback, setProgressCheckFeedback] = useState<
    Record<string, ProgressCheckFeedbackBagDto>
  >(
    participants.reduce(
      (acc, participant) => {
        acc[participant.id] = {
          accuracy: 0,
          comprehension: 0,
          vocabularyRange: 0,
          fluency: 0,
          recommendation: [],
          notes: "",
          passed: null,
          userId: participant.id,
          noShow: false,
        };
        return acc;
      },
      {} as Record<string, ProgressCheckFeedbackBagDto>,
    ),
  );

  function isCompleteProgressCheckFeedback(
    pf: ProgressCheckFeedbackBagDto | undefined,
  ): boolean {
    if (!pf) return false;
    if (pf.noShow) return true;
    return (
      (pf.accuracy ?? 0) > 0 &&
      (pf.comprehension ?? 0) > 0 &&
      (pf.fluency ?? 0) > 0 &&
      (pf.vocabularyRange ?? 0) > 0 &&
      pf.passed !== null &&
      pf.passed !== undefined
    );
  }

  const getSelectedParticipant = useCallback(
    (
      participants: readonly ProgressCheckUserFragment[],
    ): ProgressCheckUserDto => {
      const participant = participants[selectedParticipantIndex];
      if (!participant)
        throw new Error(`Cannot find participant ${selectedParticipantIndex}`);
      return participant;
    },
    [selectedParticipantIndex],
  );

  function getSizeOfCompletedLessonFeedbacks(
    lessonFeedbacks: Record<string, ProgressCheckFeedbackBagDto>,
  ) {
    return _values(lessonFeedbacks).filter(isCompleteProgressCheckFeedback)
      .length;
  }

  function isTheLastOne(participants: readonly ProgressCheckUserFragment[]) {
    const size = getSizeOfCompletedLessonFeedbacks(progressCheckFeedback);
    const participant = getSelectedParticipant(participants);
    return (
      size >= participants.length - 1 &&
      !isCompleteProgressCheckFeedback(progressCheckFeedback[participant.id])
    );
  }

  function getDefaultValueByParticipantId(
    participantId: string,
  ): ProgressCheckFormInput {
    const values = progressCheckFeedback[participantId];
    if (values)
      return {
        notes: values.notes ?? "",
        accuracy: values.accuracy ?? 0,
        comprehension: values.comprehension ?? 0,
        fluency: values.fluency ?? 0,
        passed: values.passed ?? undefined,
        recommendation: values.recommendation ?? [],
        vocabularyRange: values.vocabularyRange ?? 0,
        noShow: values.noShow ?? false,
      };

    return {
      notes: "",
      accuracy: 0,
      comprehension: 0,
      fluency: 0,
      passed: undefined,
      recommendation: [],
      vocabularyRange: 0,
      noShow: false,
    };
  }

  const areSameProgressCheck = useCallback(
    (
      a: ProgressCheckFeedbackBagDto | undefined,
      b: ProgressCheckFeedbackBagDto | undefined,
    ) => {
      return (
        a?.accuracy === b?.accuracy &&
        a?.fluency === b?.fluency &&
        a?.comprehension === b?.comprehension &&
        a?.notes === b?.notes &&
        a?.userId === b?.userId &&
        a?.vocabularyRange === b?.vocabularyRange &&
        a?.recommendation?.length === b?.recommendation?.length &&
        !!(
          (!a?.recommendation && !b?.recommendation) ||
          a?.recommendation?.every((r) => b?.recommendation?.includes(r))
        ) &&
        a?.passed === b?.passed &&
        a?.noShow === b?.noShow
      );
    },
    [],
  );

  const onChangeCurrentValuation = useCallback(
    (data: ProgressCheckFormInput) => {
      setProgressCheckFeedback((prev) => {
        const selectedParticipant = getSelectedParticipant(participants);
        const newPf: ProgressCheckFeedbackBagDto = {
          accuracy: data.accuracy,
          comprehension: data.comprehension,
          vocabularyRange: data.vocabularyRange,
          fluency: data.fluency,
          recommendation: data.recommendation,
          notes: data.notes ?? "",
          userId: selectedParticipant.id,
          passed: data.passed,
          noShow: data.noShow,
        };
        if (areSameProgressCheck(prev[selectedParticipant.id], newPf))
          return prev;
        return {
          ...prev,
          [selectedParticipant.id]: newPf,
        };
      });
    },
    [getSelectedParticipant, participants, areSameProgressCheck],
  );

  function hasCompletedFeedback(
    lessonFeedbacks: Record<string, ProgressCheckFeedbackBagDto>,
  ) {
    return _values(lessonFeedbacks).every(isCompleteProgressCheckFeedback);
  }

  function getFirstNotCompletedParticipantIndex(
    participants: readonly ProgressCheckUserFragment[],
    lessonFeedbacks: Record<string, ProgressCheckFeedbackBagDto>,
  ): number {
    return participants.findIndex(
      (p) => !isCompleteProgressCheckFeedback(lessonFeedbacks[p.id]),
    );
  }

  async function onSave(
    participants: readonly ProgressCheckUserFragment[],
    data: ProgressCheckFormInput,
  ): Promise<void> {
    if (!progressCheck) throw new Error("Progress check not defined");
    const selectedParticipant = getSelectedParticipant(participants);
    const newProgressCheckFeedback: Record<
      string,
      ProgressCheckFeedbackBagDto
    > = {
      ...progressCheckFeedback,
      [selectedParticipant.id]: {
        accuracy: data.accuracy,
        comprehension: data.comprehension,
        vocabularyRange: data.vocabularyRange,
        fluency: data.fluency,
        recommendation: data.recommendation,
        notes: data.notes ?? "",
        userId: selectedParticipant.id,
        passed: data.passed,
        noShow: data.noShow,
      },
    };

    if (hasCompletedFeedback(newProgressCheckFeedback)) {
      try {
        await giveProgressCheckFeedback(
          _values(newProgressCheckFeedback).map((v) => ({
            progressCheckId: progressCheck.id,
            accuracy: v.accuracy ?? 0,
            comprehension: v.comprehension ?? 0,
            fluency: v.fluency ?? 0,
            vocabularyRange: v.vocabularyRange ?? 0,
            noShow: v.noShow,
            notes: v.notes ?? "",
            passed: v.noShow ? false : v.passed!,
            recommendation: v.recommendation ?? [],
            userId: v.userId,
          })),
        );
      } catch (e) {
        const err = e as GraphQLError;
        showPopup("Error", err?.message);
        throw e;
      }
      goToProgressCheckFeedbackThankYou(progressCheck.id);
    } else {
      setSelectedParticipantIndex(
        getFirstNotCompletedParticipantIndex(
          participants,
          newProgressCheckFeedback,
        ),
      );
    }
    setProgressCheckFeedback(newProgressCheckFeedback);
  }

  if (participants.length <= 0)
    return (
      <Layout>
        <div className="flex justify-center">
          <ErrorFeedback
            title="Oops!"
            message="This classroom has no participants"
          />
        </div>
      </Layout>
    );
  const selectedParticipant = getSelectedParticipant(participants);
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <Layout>
        <div className="flex flex-col space-y-2">
          <Card>
            <ScreenTitleBlock
              title={`Progress Check of ${dayjs(
                progressCheck.startedAtUtc,
              ).format("DD MMM YYYY [at] HH:mm")}`}
              subTitle={`Speaking level: ${progressCheck.speakingLevel}`}
              hideLineDivider
            />
          </Card>
          <Card>
            <p>
              To view the feedback provided by other teachers to the students in
              this progress check, please refer to{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={progressCheck.feedbackReportUrl}
                className="underline text-blue-600"
              >
                this report
              </a>
            </p>
          </Card>
          <Card>
            <div>
              <ProgressBar
                label="Feedback"
                max={participants.length}
                current={getSizeOfCompletedLessonFeedbacks(
                  progressCheckFeedback,
                )}
              />
            </div>
            <div>
              <Tabs>
                {participants.map((participant, i) => {
                  return (
                    <TabItem
                      key={participant.id}
                      onClick={() => setSelectedParticipantIndex(i)}
                      selected={selectedParticipantIndex === i}
                      label={
                        <p className="flex space-x-2">
                          <span>
                            {participant.givenName} {participant.familyName}
                          </span>
                          <span>
                            {isCompleteProgressCheckFeedback(
                              progressCheckFeedback[participant.id],
                            )
                              ? "✅"
                              : "❌"}
                          </span>
                        </p>
                      }
                    />
                  );
                })}
              </Tabs>
            </div>
            <ProgressCheckForm
              disabledValues={{
                passed: {
                  disabled: !selectedParticipant.canImproveSpeakingLevel,
                  reason: `This student is a Voyager so they can't move up to the next level.`,
                },
              }}
              key={selectedParticipant.id}
              defaultValues={getDefaultValueByParticipantId(
                selectedParticipant.id,
              )}
              participant={participants[selectedParticipantIndex]}
              saveButtonLabel={
                isTheLastOne(participants) ||
                hasCompletedFeedback(progressCheckFeedback)
                  ? "Send"
                  : "Save and proceed"
              }
              onSave={(data) => onSave(participants, data)}
              onChange={onChangeCurrentValuation}
            />
          </Card>
        </div>
      </Layout>
    </>
  );
}

export function GiveProgressCheckFeedbackScreen() {
  const { classRoomId } = useClassRoomIdParamsOrThrow();
  const { loading, progressCheck, participants } =
    useProgressCheckUserList(classRoomId);
  if (loading) return <FullPageLoading />;
  if (!progressCheck)
    return (
      <Layout>
        <div className="flex justify-center">
          <ErrorFeedback
            title="Oops!"
            message="This progress check does not exist"
          />
        </div>
      </Layout>
    );

  return (
    <GiveProgressCheckFeedbackScreenInner
      progressCheck={progressCheck}
      participants={participants}
    />
  );
}
